import React from 'react'
import cn from 'classnames'
import { Button, Card, formatWithDecimalPlaces } from 'shared'
import {
  WithdrawalFormFields,
  WithdrawalTypeEnum,
} from '../WithdrawFundsModalGeneric'
import { AccountFund } from '../../../redux/account-funds/account-funds.model'
import './MFWithdrawCompleted.scss'

type MFWithdrawCompletedProps = {
  values: WithdrawalFormFields
  funds: AccountFund[]
  onNext: () => void
}

type LabelValueProps = {
  className?: string
  label: string
  value: string
  children?: React.ReactNode
}

const LabelValue = ({ className, label, value, children }: LabelValueProps) => (
  <div className={cn('label-value', { [`${className}`]: !!className })}>
    <p className="mt-0 mb-0">{label}</p>
    <p className="color-midnight text-bold mt-2xs mb-0">{value}</p>
    {children}
  </div>
)

export const MFWithdrawCompleted = ({
  values,
  funds,
  onNext,
}: MFWithdrawCompletedProps) => (
  <div className="mf-withdraw-completed">
    <Card>
      <h5>Pending withdrawal</h5>

      <p>Thank you, we have received your withdrawal request.</p>

      <p>
        Once we&apos;ve verified your bank account, we will process your
        withdrawal. Withdrawal can take 2 to 5 business days.
      </p>

      <LabelValue
        label="Type"
        value={`
        ${
          values.withdrawalType === WithdrawalTypeEnum.Partial
            ? 'Partial'
            : 'Full'
        } withdrawal
      `}
      />

      {values.withdrawalType === WithdrawalTypeEnum.Partial && (
        <LabelValue
          className="pb-0"
          label="Amount"
          value={`$${formatWithDecimalPlaces(values.withdrawalAmount, 2)}`}
        >
          {values.withdrawalFunds?.length > 0 && (
            <ul className="funds-list">
              {(values.withdrawalFunds || []).map((fund) => (
                <li
                  className="flex-row align-center text-regular color-midnight py-xs"
                  key={fund.assetCode}
                >
                  <span className="text-bold">
                    {
                      funds.find((f) => f.assetCode === fund.assetCode)
                        .fundDisplayName
                    }
                  </span>
                  <span className="ml-auto">
                    ${formatWithDecimalPlaces(fund.amount, 2)}
                  </span>
                </li>
              ))}
            </ul>
          )}
        </LabelValue>
      )}

      {!!values.bankAccountName && (
        <LabelValue label="Account name" value={values.bankAccountName} />
      )}
      <LabelValue
        label="Account number"
        value={
          values.useExistingBankAccount
            ? 'Existing bank account'
            : values.bankAccountNumber
        }
      />
    </Card>

    <div className="flex-row mt-lg">
      <Button className="ml-auto" type="submit" onClick={onNext}>
        Done
      </Button>
    </div>
  </div>
)
