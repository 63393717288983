import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress'
import cn from 'classnames'
import './loading-spinner.scss'

type LoadingSpinnerProps = {
  className?: string
  center?: boolean
} & Pick<CircularProgressProps, 'size'>

export const LoadingSpinner = ({
  className,
  center,
  size,
}: LoadingSpinnerProps) => {
  const divClass = cn('loading-spinner', {
    [`${className}`]: !!className,
    center: center,
  })

  return (
    <div className={divClass}>
      <CircularProgress color="primary" size={size} />
    </div>
  )
}

export default LoadingSpinner
