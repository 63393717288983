import { useState } from 'react'
import { ProductSlug } from 'shared'
import './MFWithdrawFundsModal.scss'
import { WithdrawFundsModalProps } from '../WithdrawFundsModal'
import {
  WithdrawalFormFields,
  WithdrawalTypeEnum,
} from '../WithdrawFundsModalGeneric'
import { MFWithdrawForm } from './MFWithdrawForm'
import MFWithdrawReview from './MFWithdrawReview'
import { MFWithdrawCompleted } from './MFWithdrawCompleted'
import { ProductModal } from '../../modal/ProductModal'
import CurrencyTotal from '../../currency/CurrencyTotal'

enum WITHDRAW_STEP {
  FORM = 'FORM',
  REVIEW = 'REVIEW',
  COMPLETED = 'COMPLETED',
}

export const MFWithdrawFundsModal = (props: WithdrawFundsModalProps) => {
  const [currentStep, setCurrentStep] = useState(WITHDRAW_STEP.FORM)
  const [values, setValues] = useState<WithdrawalFormFields>()
  const [isLoading, setIsLoading] = useState(false)

  const { account, onClose } = props

  const renderStep = () => {
    if (currentStep === WITHDRAW_STEP.FORM) {
      return (
        <MFWithdrawForm
          account={account}
          funds={props.funds}
          onCancel={onClose}
          onSubmit={(values) => {
            setValues(values)
            setCurrentStep(WITHDRAW_STEP.REVIEW)
          }}
          values={values}
          isDisable={isLoading}
        />
      )
    }

    if (currentStep === WITHDRAW_STEP.REVIEW) {
      return (
        <MFWithdrawReview
          account={account}
          values={values}
          onBack={() => setCurrentStep(WITHDRAW_STEP.FORM)}
          isLoading={isLoading}
          onConfirm={() => {
            setIsLoading(true)
            // TODO: API call
            setCurrentStep(WITHDRAW_STEP.COMPLETED)
          }}
        />
      )
    }

    return (
      <MFWithdrawCompleted
        values={values}
        funds={account.funds}
        onNext={onClose}
      />
    )
  }

  return (
    <ProductModal
      className="managed-funds-withdraw-modal"
      shortBackground={currentStep === WITHDRAW_STEP.REVIEW}
      productSlug={ProductSlug.FFMF}
      title="Withdraw funds"
      subtitle={`Managed Funds - ${account.accountNumber}`}
      onClose={onClose}
    >
      {currentStep === WITHDRAW_STEP.COMPLETED ? (
        <>
          <CurrencyTotal
            className="color-white"
            value={
              values.withdrawalType === WithdrawalTypeEnum.Partial
                ? account.accountTotal - values.withdrawalAmount
                : 0
            }
            size="lg"
          />
          <em className="balance-after-withdrawal color-white text-small mb-md">
            Approximate balance after pending withdrawal
          </em>
        </>
      ) : (
        <CurrencyTotal
          className="color-white mb-md"
          value={account.accountTotal}
          size="lg"
        />
      )}
      {renderStep()}
    </ProductModal>
  )
}
